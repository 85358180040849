import React from 'react'
import MainNav from '../../MainNav'
import { 
  Typography,
  Grid,
  GridRow,
  GridCell,
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableRow,
  DataTableHeadCell,
  DataTableBody,
  DataTableCell,
  Button,
  SimpleMenu,
  MenuItem,
  TextField,
  CircularProgress,
  Snackbar,
  SnackbarAction
} from "rmwc";
import { ChargeContext } from '../../context/ChargeContext';
//import {  GET_BILLINGS_TO, POST_BILLING } from '../../api';
import { getBillings } from './ChargeService'
import Pagination from '../Pagination/Pagination'
import useForm from '../../Hooks/useForm';
import ChargesTable from './ChargesTable'
import ChargeAdd from './ChargeAdd';
const ChargesIndex = () => {
  const {data, getData, paginate, paginateUpdate, loaded} = React.useContext(ChargeContext)
  const [toBill, setToBill] = React.useState([])
  const [billinging, setBillinging] = React.useState(false)
  const [showperiod, setShowperiod] = React.useState(false)
  const [paginateBill, setPaginateBill] = React.useState({total:0, perPage:10, page:1, lastpage:0})
  const [messageSnack,setMessageSnack] = React.useState('')
  const token = window.localStorage.getItem('token')
  const [dueDate, setDueDate] = React.useState(null)
  const [description, setDescription] = React.useState(null)
  const [open, setOpen] = React.useState(false);
 


  const getToBill = async ()=>{
    try {
      // const {url, options} = GET_BILLINGS_TO(token, paginateBill)
      // const response = await fetch(url, options)
      // if(!response.ok) throw new Error(response.statusText)
      // const {billings} = await response.json()
      // setToBill(billings.data)
      // setPaginateBill({total:billings.total, perPage:billings.perPage, page:billings.page, lastpage:billings.lastpage})
    } catch (error) {
      console.log(error)
    }
  }

  const toBillCards = async (id)=>{  
    try {
      setMessageSnack('')
      const body ={
        due_date:dueDate,
        description: description,
        company_id:id
      }
      // const {url, options} = POST_BILLING(token, body)
      // const response = await fetch(url, options)
      // if(!response.ok) throw new Error(response.statusText)
      // const {billing} = await response.json()
      // setMessageSnack("Fatura gerada com sucesso!")
      // setOpen(true)
      // getData()
    } catch (error) {
      console.log(error)
    }
  }

 

  

 

  const handleSearchToStatus = async event =>{
    event.preventDefault()
    try {
      const status = ["GERADA", "ENVIADA", "PAGA", "CANCELADA"]
      const search = `&status=${status[event.target.value]}`
      getData(search)
    } catch (error) {
      console.log(error)
    }
  }
  const handleSearch = async event =>{
    event.preventDefault()
    try {
      const search = `${event.target.nameorcnpj.value}`
      getData(search)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSearchToPeriod = async event =>{
    event.preventDefault()
    try{
      const search = `&start=${event.target.start_date.value}&end=${event.target.end_date.value}`
      getData(search)
    }catch(error){
      console.log(error)
    }
  }

 
  const paginateUpdateBilling =async event =>{
    try {
      paginateBill.page=event.target.id
        // setPaginateBill(paginateBill)
        // const {url, options} = GET_BILLINGS_TO(token, paginateBill)
        // const response = await fetch(url, options)
        // if(!response.ok) throw new Error(`Error: ${response.statusText}`)
        // const {billings} = await response.json()
        // setToBill(billings.data)      
    } catch (error) {
        console.log(error)
    }
  }



  React.useEffect(()=>{

  }, [])

    return (
        <>

        <MainNav/>
          <div className={"PageContainer"}>
          <div className={"PageTitle"}>        
            <h1><Typography use="headline1">Cobranças</Typography></h1>             
          </div>
            {loaded &&
            <div className={"loading"}> 
              <CircularProgress size={125} />
            </div>
            }
          { !loaded && <div>
            <Grid className={"CustomContainer"}>
              <GridRow>
                <GridCell span={12}>
                <GridCell span={4}>                     
                  <Button className={"BtnDefaultTmenu"} label="Filtrar por Período" icon="event_note  " onClick={()=>{setShowperiod(!showperiod)}}/>                      
                  <SimpleMenu handle={<Button className={"BtnDefaultTmenu"} label="Filtrar por Status" icon="filter_list" />}>
                    <MenuItem value="0" onClick={handleSearchToStatus} >Gerada</MenuItem>
                    <MenuItem value="1" onClick={handleSearchToStatus}>Enviada</MenuItem>
                    <MenuItem value="2" onClick={handleSearchToStatus}>Paga</MenuItem>
                    <MenuItem value="3" onClick={handleSearchToStatus} >Cancelada</MenuItem>
                  </SimpleMenu>          
                  
                </GridCell>
                { showperiod &&
                <GridCell span={4}>
                  <form onSubmit={handleSearchToPeriod}>
                    <TextField label="Data inicial" type="date" name="start_date"/><TextField label="Data final" type="date" name="end_date"/>
                    <Button label="Filtrar" outlined icon="search" className={"BtnDefaultSearch"} />  
                  </form>
                </GridCell>}                    
                <GridCell span={4}>
                  <form onSubmit={handleSearch}>                      
                    <TextField className={"CustomInputSearch"} outlined name="nameorcnpj"label="Buscar por Nome ou CNPJ..." />
                    <Button label="Pesquisar" outlined icon="search" className={"BtnDefaultSearch"}/>
                  </form>
                </GridCell>
                </GridCell>  
              </GridRow>       
            </Grid>
            <Grid className={"CustomContainer"}>
            <div className={"PageSubtitle"}> 
            <Typography use="headline4">Cadastrar fatura</Typography>
            </div>
              <ChargeAdd />
            </Grid>
           <ChargesTable /> 
           
            </div>
                     }

<Snackbar
        open={open}
        onClose={evt => setOpen(false)}
        message={messageSnack}
        dismissesOnAction
        action={
          <SnackbarAction
            label="OK"
            onClick={() => console.log('Click Me')}
          />
        }
      />
        </div> 
        </>
    )
}

export default ChargesIndex
