import React from 'react'
import {
    Dialog, DialogTitle, DialogContent,
    DialogActions, DialogButton,
    Snackbar, SnackbarAction, TextField
} from 'rmwc'
import { updatePlan, deletePlan } from './TaxServices'
import { PlanContext } from '../../PlanContext'
import useForm from '../../Hooks/useForm'
const TaxDialog =({
    title, action, open, setOpen, plan
})=>{

  const token = window.localStorage.getItem('token')
  const [content, setContent] = React.useState(null)
  const [message, setMessage] = React.useState('')   
  const [openSnack, setopenSnake] = React.useState(false) 
  const {addPlans} = React.useContext(PlanContext)
  const name = useForm()
  const value = useForm()
  const tolerance = useForm()
  
  const toDelete=()=>{
      if(deletePlan(token, plan.id)){        
        addPlans()
        setMessage(`Plano ${plan.name} excuildo!`)
        setopenSnake(true)
      }
    }
  
  const toEdit=()=>{
    const body={
      name: name.value,
      value: value.value,
      tolerance: tolerance.value
    }      
    if(updatePlan(token, body, plan.id)){
      addPlans()
      setMessage(`Plano ${plan.name} alterado!`)
      setopenSnake(true)
    }
  }

  const actionAply =()=>{
      if(action==='delete'){        
        toDelete()
      }else if(action === 'edit'){
        toEdit()
      }
  }

  const addContent =()=>{
    addPlans()  
    if(action === 'delete'){
      setContent('Esta ação não poderá ser desefeita!')
    }else{
      name.setValue(plan?plan.name:'')
      value.setValue(plan?plan.value:'')
      tolerance.setValue(plan?plan.tolerance:'')
    }
  }

  React.useEffect(()=>{
    addContent()
  },[plan])

  return(
      <>
        <Dialog
          open={open}
          onClose={(evt) => {
              console.log(evt.detail.action);
              setOpen(false);
          }}
          onClosed={(evt) => console.log(evt.detail.action)}
          >
          <DialogTitle>{title}</DialogTitle>
          {action === 'delete' && <DialogContent>{content}</DialogContent>}
            {action === 'edit' &&
             <DialogContent>
             <TextField placeholder="Nome" {...name} type="text"/>
             <TextField  placeholder="Valor" {...value} type="number"/>
             <TextField  placeholder="Tolerância"  {...tolerance} type="number"/> 
             </DialogContent>
          }
          <DialogActions>
              <DialogButton action="close" icon="cancel" className={"btnCancel"}>Cancelar</DialogButton>
              <DialogButton action="accept" isDefaultAction onClick={actionAply} icon="done" className={"TmenuSuccess"}>
              Confirmar
              </DialogButton>
          </DialogActions>
          </Dialog>

          <Snackbar
          open={openSnack}
          onClose={(evt) => setopenSnake(false)}
          message={message}
          dismissesOnAction
          action={
            <SnackbarAction
              label="OK"
              onClick={() => console.log('OK')}
            />
          }
          />
      
      </>
  )

}

export default TaxDialog