import React from 'react'
import { Button, Snackbar, SnackbarAction } from 'rmwc'
import { getCompanies } from '../Companies/CompanyService'
import { postBilling } from './ChargeService'
import { ChargeContext } from '../../context/ChargeContext';

const ChargeAdd = ()=>{
    const {getData} = React.useContext(ChargeContext)
    const [companies, setCompanies] = React.useState([])
    const [messageSnack,setMessageSnack] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const token = window.localStorage.getItem('token')

    const getCompany = async ()=>{
        const data = await getCompanies(token, {total:0, perPage:null, page:1, lastpage:0}, null)
        setCompanies([...data])        
    }
    const handleSublit = async event =>{
        event.preventDefault()
        const body = {
            due_date: event.target.due_date.value,
            company_id: event.target.company.value.slice(0,1),
            description: event.target.description.value
        }
        const add = await postBilling(token, body)
        if(add){
            event.target.reset()
            setMessageSnack("Fatura gerada com sucesso!")
            setOpen(true)
            getData()
            console.log(add)
        }
    }

    React.useEffect(()=>{
        getCompany()
    },[])

    return(<>
            
        <form onSubmit={handleSublit}>
        <div className='forAddBilling'>
       <div className='item'>
       <label>Selecione o restauante</label>     
       <input list="companys" name="company" id="company" className="datalistCompany"/>
        <datalist id="companys" className="datalistCompany" >
            {companies.map(company=>{
                return(
                    <option  value={`${company.id} - ${company.name}`}/>
                )
            })}
        </datalist>
       </div>
        <div className='item'>
            <label>Data de Vencimento</label>
            <input name="due_date" type="date" className="dataDateCompany"/>
        </div>
        <div className='item'>
            <label>Descrição</label>
            <input name="description" type="text" className="datadescriptionCompany"/>
        </div>
        </div>
        <div className='btnItem'>
        <Button label="Cadastrar" icon="add" className={'BtnDefaultSearch'} type="submit"/>
        </div>
        </form>
        <Snackbar
        open={open}
        onClose={evt => setOpen(false)}
        message={messageSnack}
        dismissesOnAction
        action={
          <SnackbarAction
            label="OK"
            onClick={() => console.log('Click Me')}
          />
        }
      />
        </>
    )

}

export default ChargeAdd