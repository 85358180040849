import React from 'react'
import { 
  Typography,
  Grid,
  GridRow,
  GridCell,
  
  Button,
  Badge,
  SimpleMenu,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
  CircularProgress
} from "rmwc";
import MainNav from '../../MainNav';
import { GET_PLANS } from '../../api';
import TaxTable from './TaxTable';
import TaxAdd from './TaxAdd';
import { PlanContext } from '../../PlanContext';
const TaxRulesIndex = () => {
  const {plans, addPlans, loaded} = React.useContext(PlanContext)
  //const [plans, setPlans] = React.useState([])
  const [add, setAdd] = React.useState(false)
  //const [loaded, setLoaded] = React.useState(true)
  // const getPlans =async ()=>{
  //   try {
  //     const token = window.localStorage.getItem('token')
  //     if(!token) throw new Error('Error: Token inválido!')
  //     const {url, options} = GET_PLANS(token)
  //     const response = await fetch(url, options)
  //     if(!response.ok) throw new Error(`Error: ${response.statusText}`)
  //     const {plans} = await response.json()
  //     setPlans(plans)
  //   } catch (error) {
  //     console.log(error)
  //   }
  //   finally{
  //     setLoaded(false)
  //   }
  // }


  React.useEffect(()=>{

  },[])

    return (
      <>
        
          <MainNav />
          <div className={"PageContainer"}>
            <div className={"PageTitle"}>        
              <h1><Typography use="headline1">Gestão de Taxas</Typography></h1>             
            </div>
            
          
            {loaded && <div className={"loading"}>  <CircularProgress size={125} /></div>}
          
            
          
        { !loaded && 
          <div>
          <Grid className={"CustomContainer"}>
              <GridRow>
                    <GridCell span={6}>
                      <Button onClick={()=>setAdd(!add)} className={"BtnDefaultTmenu"} label={add?"Fechar":"Cadastrar Taxa"} icon="add" />     
                    </GridCell>                    
                </GridRow>
          </Grid>

          { add && 
            <TaxAdd add={add} setAdd={setAdd} />
          }       
            
          <Grid className={"CustomContainer"}>
            <GridRow>
              <GridCell>
                {plans && <TaxTable plans={plans}/>}
              </GridCell>
            </GridRow>
          </Grid>
          </div>
          }
        </div> 
      </>
    )
}

export default TaxRulesIndex
