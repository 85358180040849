import { GET_BILLINGS, POST_BILLING, SEND_BILLING, UPDATE_BILLING } from "../../api";

export const getBillings = async (token, paginate, search=null)=>{
    try {
        const { url, options } = GET_BILLINGS(token,paginate, search)
        const response = await fetch(url, options)
        if(!response.ok) throw new Error(`Error: ${response.statusText}`)
        const {billings} = await response.json()
        return billings
    } catch (error) {
        console.log(error)
        return null
    }
}

export const sendBilling = async (token, id, urlPayment)=>{
    try {
        const body = {billing_id:id,billing_link:urlPayment}
        const {url, options} = SEND_BILLING(token,body)
        const response = await fetch(url, options)
        console.log(id, urlPayment)
        if(!response.ok) throw new Error(response.statusText)
        return true
    } catch (error) {
        console.log(error)
        return false
    }
}

export const updateBilling = async (token, id, body)=>{
    try {
        const {url, options} = UPDATE_BILLING(token, id, body)
        const response = await fetch(url, options)
        if(!response.ok) throw new Error(response.statusText)
        return true
    } catch (error) {
        console.log(error)
        return false
    }
}

export const postBilling = async (token, body)=>{
    try {
        const {url, options} = POST_BILLING(token, body)
        const response = await fetch(url,options)
        if(!response.ok) throw new Error(response.statusText)
        return true
    } catch (error) {
        console.log(error)
        return false
    }
}