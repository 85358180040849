const prod = {
    url: {
     API_URL: `https://api.tmenu.com.br/v1/`,
   }
   };
   const dev = {
    url: {
     API_URL: `http://localhost:3333/v1/`
    }
   };
   export const config = process.env.NODE_ENV === `development` ? dev : prod;