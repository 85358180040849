import React from 'react'
import {
  Typography, Grid, GridRow, GridCell, DataTable, DataTableContent, DataTableBody,
  DataTableHead, DataTableHeadCell, DataTableRow, DataTableCell, SimpleMenu,
  MenuItem, Badge, Button, Dialog, DialogTitle, DialogContent, DialogActions,
  DialogButton, TextField, Snackbar, SnackbarAction
} from 'rmwc'
import Pagination from '../Pagination/Pagination'
import { ChargeContext } from '../../context/ChargeContext'
import { sendBilling, updateBilling } from './ChargeService'
const ChargesTable =()=>{
    const {data, getData, paginate, paginateUpdate} = React.useContext(ChargeContext)
    const [urlPayment,setUrlPayment] = React.useState('')
    const [dialog, setDialog] = React.useState(false)
    const [id, setId] = React.useState(0)
    const [messageSnack,setMessageSnack] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const token = window.localStorage.getItem('token')
    const showDialog = async (event) =>{
        event.preventDefault()
        setId(event.target.id)
        setId(event.target.id)    
        setDialog(true)
      }


    const updateStatus = async event =>{
        event.preventDefault()
        try {
          setMessageSnack('')
          const status = ["CANCELADA", "PAGA"]
          const body ={
            status: status[event.target.value]
          }
          if(await updateBilling(token, event.target.id, body)){
            setMessageSnack("Status atualizado com sucesso!")
            setOpen(true)
            getData()
        }
        } catch (error) {
          console.log(error)
        }
      }

      const openLink =(link)=>{
        window.open(link)
      }


      const handleSendBilling = async (event) =>{
        event.preventDefault()
        try {
          setMessageSnack('')
          if(await sendBilling(token, id, urlPayment)){
            setMessageSnack("Fatura enviada com sucesso!")
            setOpen(true)
            getData()        
            setUrlPayment('')
            getData()
          
          } 
        }
        catch (error) {
          console.log(error)  
        }
      }
    React.useEffect(()=>{
        getData()
    },[])

    return(
      <>
      <Dialog 
      open={dialog}
      onClose={evt => {
        console.log(evt.detail.action);
        console.log()
        setDialog(false);
        
      }}
      onClosed={evt =>{
        setUrlPayment('')
        console.log(evt.detail.action)
      } }
      >
      <DialogTitle>Informe a url</DialogTitle>
      <form onSubmit={handleSendBilling}>
      <DialogContent>
        <TextField  outlined  className={"CustomInputSearch"} name="urlPayment" value={urlPayment} onChange={({target})=>setUrlPayment(target.value)} />  
      </DialogContent>
      <DialogActions>
        <DialogButton action="accept" isDefaultAction>
          Enviar
        </DialogButton>
      </DialogActions>
      </form>
    </Dialog>

        <Grid className={"CustomContainer"}>
        <div className={"PageSubtitle"}> 
        <Typography use="headline4">Cobranças Faturadas</Typography>
        </div>
        <GridRow>
          <GridCell>
            <DataTable className={"TabelaProdutos"}>
              <DataTableContent>
                <DataTableHead>
                  <DataTableRow>
                    <DataTableHeadCell>Estabelecimento</DataTableHeadCell>                        
                    <DataTableHeadCell alignEnd>Referência</DataTableHeadCell>
                    <DataTableHeadCell alignEnd>Total da Fatura</DataTableHeadCell>
                    <DataTableHeadCell alignEnd>Ações</DataTableHeadCell>
                    <DataTableHeadCell alignEnd>Link</DataTableHeadCell>
                    <DataTableHeadCell alignEnd>Status</DataTableHeadCell>                     
                  </DataTableRow>
                </DataTableHead>
                <DataTableBody>
                {data.map( billing =>{
                    return(
                      
                      <DataTableRow key={billing.id}>
                       
                  <DataTableCell><a href="/">{billing.company.name}</a></DataTableCell>
                  <DataTableCell alignEnd>{billing.description}</DataTableCell>
                  <DataTableCell alignEnd className={"strong"}>R$ {billing.value}</DataTableCell>
                   <td>    
                   
                        
                    <SimpleMenu handle={<Button label="Selecione" icon="expand_more" />}>
                      <MenuItem id={billing.id}  onClick={showDialog}>
                        Enviar               
                      </MenuItem>
                      <MenuItem id={billing.id}  value="1" onClick={updateStatus}>
                      Marcar como Paga
                      </MenuItem>
                      <MenuItem id={billing.id}  value="0" onClick={updateStatus}>
                      Marcar como Cancelada
                      </MenuItem>                            
                    </SimpleMenu>
                  </td>
                  <DataTableCell alignEnd><Button label="Fatura" icon="link" outlined type="button" onClick={()=>openLink(billing.billing_link)}/></DataTableCell>
                  <DataTableCell alignEnd>
                    { billing.status ==="GERADA" && 
                      <Badge className={"TmenuInProcess"} align="inline" label="Gerada" />
                    }
                    { billing.status ==="ENVIADA" && 
                      <Badge className={"TmenuInfo"} align="inline" label="Enviada" />
                    }
                    { billing.status ==="PAGA" && 
                      <Badge className={"TmenuSuccess"} align="inline" label="Paga" />
                    }
                    { billing.status ==="CANCELADA" && 
                      <Badge className={"TmenuDanger"} align="inline" label="Cancelada" />
                    }                                                                                
                  </DataTableCell>                        
                </DataTableRow>
                    )
                  }
                )
                  
                 } 
                </DataTableBody>
              </DataTableContent>
            </DataTable>
            </GridCell>
            </GridRow>
            <GridRow>
              <Pagination paginate={paginate} paginateUpdate={paginateUpdate}/>
            </GridRow>
        </Grid>
        <Snackbar
        open={open}
        onClose={evt => setOpen(false)}
        message={messageSnack}
        dismissesOnAction
        action={
          <SnackbarAction
            label="OK"
            onClick={() => console.log('Click Me')}
          />
        }
      />
        </>
    )
}

export default ChargesTable