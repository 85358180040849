import React from 'react';
import { Typography, TextField, Grid, GridRow, GridCell, Button } from "rmwc";
import { UserContext } from '../../UserContext';
import useForm from '../../Hooks/useForm';
import './Login.css';
const Login = () => {
  const email = useForm('email')
  const password = useForm()
  const { userLogin, login , error, loading} = React.useContext(UserContext);
  async function handleSubimit(event){  
    event.preventDefault()  
    if(email.validade() && password.validade() ){
      userLogin({email: event.target.email.value, password:event.target.password.value})
    }    
  }
  return (
    <>

     <div className={"PageContainer ContainerPageLogin"}>
     <div className={"PageTitle PageTitleLogin"}>
        <h1><Typography use="headline1">LOGIN</Typography></h1>
      </div>
      <form className="formContainer formContainerLogin" onSubmit={handleSubimit}>
        <Grid>
          <GridRow>
            <GridCell span={12}>
              <TextField  name="email" type="email" fullwidth placeholder="Email" {...email} required/>
              <p>{email.error}</p>
              </GridCell>
          </GridRow>
          <GridRow>
            <GridCell span={12}>
              <TextField  name="password" type="password" fullwidth placeholder="Password" {...password} required/>
              <p>{password.error}</p>
              </GridCell>
          </GridRow>
          {error}
          <GridRow>
            <GridCell span={12}>
              <Button type="submit" className={"BtnDefaultTmenu"} label={loading?"Aguarde..":"Entrar"} icon="input" disabled={loading}/>
            </GridCell>
          </GridRow>
        </Grid>
      </form>
    </div>
    </>
  )
}

export default Login;