import {DELETE_PLAN, GET_PLANS, POST_PLAN, PUT_PLAN} from '../../api'


export const getPlans = async (token)=>{
    try {
        const {url, options} = GET_PLANS(token)
        const response = await fetch(url, options)
        if(!response.ok) throw new Error(response.statusText)
        const {plans} = await response.json()
        return plans
    } catch (error) {
        console.log(error)
    }
}
export const updatePlan = async (token, body, id)=>{
    try {
        const {url, options} = PUT_PLAN(token, body, id)
        const response = await fetch(url, options)
        if(!response.ok) throw new Error(response.statusText)
        return true
    } catch (error) {
        console.log(error)
    }
}

export const deletePlan = async (token, id)=>{
    try {
        const {url, options} = DELETE_PLAN(token, id)
        const response = await fetch(url, options)
        if(!response.ok) throw new Error(response.statusText)
        return true
    } catch (error) {
        console.log(error)
    }
}

export const postPlan = async (token, body)=>{
    try {
        const {url, options} = POST_PLAN(token, body)
        const response = await fetch(url, options)
        console.log(response)
        if(!response.ok) throw new Error(response.statusText)
        return true
    } catch (error) {
        console.log(error)
        return false
    }
}