import React from 'react'
import { Typography, TextField, Grid, GridRow, GridCell, Button, Switch } from "rmwc";
import MainNav from '../../MainNav'
import { CREATE_COMPANY } from '../../api'
import { useNavigate } from 'react-router-dom';

const AddCompany = () => {
  const navigate = useNavigate();
    
    const handleSubmit = async event =>{
      event.preventDefault()
      try {
        const token = window.localStorage.getItem('token')
        if(!token) throw new Error(`Error: token inválido`)
        const {url, options} = CREATE_COMPANY(token, event.target)
        const response = await fetch(url, options)
        console.log(response)
        if(!response.ok) throw new Error(`Error: ${response.statusText}`)
        navigate('/companies')
        //const {company} = await response.json() 
      } catch (error) {
        console.log(error)
      } 
    }

    
    const handleCEP = async event =>{
      event.preventDefault()
     
        try {
          const cep = event.target.value;       

          const url = `https://viacep.com.br/ws/${cep}/json/`
          const response = await fetch(url)        
          
          if(!response.ok) throw new Error(`Error: ${response.statusText}`)
          const {logradouro, bairro, localidade, uf} = await response.json();

          document.getElementById('street').value = logradouro
          document.getElementById('neighborhood').value = bairro
          document.getElementById('city').value = localidade
          document.getElementById('state').value = uf
  
        } catch (error) {
          console.log(error)
        }    
    }

    const validateCEPAndHandleCep = event =>{
      const cep = event.target.value
      //mascara de cep
      document.getElementById('cep').value = cep.replace(/\D/g, '').replace(/(\d{5})(\d)/, '$1-$2')
      //limita quantidade máxima de caracteres
      if(cep.length > 9) event.target.value = cep.substring(0, 9)
      if(cep.length === 9){
        handleCEP(event)
      }
    }

    
    const validateCNPJ = event =>{
      const cnpj = event.target.value
      //mascara de cnpj
      document.getElementById('cnpj').value = cnpj.replace(/\D/g, '').replace(/(\d{2})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1/$2').replace(/(\d{4})(\d)/, '$1-$2')
      //limita quantidade máxima de caracteres
      if(cnpj.length > 18) event.target.value = cnpj.substring(0, 18)
    }


   

    return (
        <>
        <MainNav />
        <div className={"PageContainer"}>
          <div className={"PageTitle"}>        
            <h1><Typography use="headline1">CADASTRO DE ESTABELECIMENTO</Typography></h1>             
          </div>
          <form onSubmit={handleSubmit}>
          <div className="formContainer">
          <Grid> 
                     
            <GridRow>              
              <GridCell span={12}>
                  <GridCell span={6}><TextField fullwidth placeholder="Razão Social" name="name" /></GridCell>
                  <GridCell span={6}> <TextField onInput={validateCNPJ} fullwidth placeholder="CNPJ" name="cnpj" id="cnpj"/></GridCell>
                  <GridRow>
                    <GridCell span={6}> <TextField onInput={validateCEPAndHandleCep} fullwidth placeholder="CEP" name="zipcode" id="cep"/></GridCell>                    
                    <GridCell span={6}> <TextField fullwidth placeholder="Endereço" name="street" id="street"/></GridCell>                    
                  </GridRow>
                  <GridRow>                    
                    <GridCell span={6}> <TextField fullwidth placeholder="Número" name="number" /></GridCell>
                    <GridCell span={6}> <TextField fullwidth placeholder="Complemento" name="complement" /></GridCell>
                  </GridRow>
                  <GridRow>                    
                    <GridCell span={4}> <TextField fullwidth placeholder="Bairro" name="district" id="neighborhood"/></GridCell>
                    <GridCell span={4}> <TextField fullwidth placeholder="Cidade" name="city" id="city"/></GridCell>
                    <GridCell span={4}> <TextField fullwidth placeholder="UF" name="state" id="state"/></GridCell>
                  </GridRow>
                  <GridRow>
                    <GridCell span={6}><TextField fullwidth placeholder="Telefone do Estabelecimento" name="phone" /></GridCell>
                    <GridCell span={6}><TextField fullwidth placeholder="Email" name="email" required type="email"/></GridCell>
                  </GridRow>   
                  <GridRow>
                      <GridCell span={6}><TextField fullwidth placeholder="Nome do Responsável Contratante" name="responsible" /></GridCell>
                      <GridCell span={6}><TextField fullwidth placeholder="Celular do Responsável Contratante" name="responsible_phone" /></GridCell>  
                  </GridRow>
                  <GridRow>
                      <GridCell span={6}><TextField fullwidth placeholder="Senha" name="password" type="password"/></GridCell>
                      <GridCell span={6}><TextField fullwidth placeholder="Confirme a senha" name="password_confirm" type="password"/></GridCell>  
                  </GridRow>
                  <GridRow>
                    <GridCell span={12}> 
                    <Switch defaultChecked label="Ativar retirada de R$1 nos 10% do garçom em cada comanda fechada" name="waiter_rate" />  
                    </GridCell>
                  </GridRow>
                  
                  <br/>
                    <GridRow>
                        <GridCell span={6}>  
                        <Button className={"BtnDefaultTmenu"} label="Salvar" icon="save" /> 
                        <Button className={""} onClick={()=>{window.history.back()}} label="Voltar" />     
                        </GridCell>                        
                    </GridRow>         
                  
                </GridCell>
            </GridRow>
           
            
          </Grid>
              
          </div>
          </form>        
        </div>
        </>
    )
}

export default AddCompany
