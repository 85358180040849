import React from 'react';
import {getPlans} from './components/TaxRules/TaxServices'
export const PlanContext = React.createContext();

export const PlanStorage = ({ children }) => {
    const [plans, setPlans] = React.useState([])
    const [loaded, setLoaded] = React.useState(true)
    const token = window.localStorage.getItem('token')

    const addPlans = async ()=>{
        try {
            const plans = await getPlans(token)
            setPlans(plans)
        } catch (error) {
            
        }finally{
            setLoaded(false)
        }
    }

    React.useEffect(()=>{
        addPlans()
    },[plans])
    return (
        <PlanContext.Provider
          value={{ plans, loaded, addPlans }}
        >
          {children}
        </PlanContext.Provider>
      );

}