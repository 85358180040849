import React from 'react'
import {
    DataTable, DataTableContent, DataTableHead, DataTableRow, DataTableHeadCell,
    DataTableBody, DataTableCell, Button, TextField
} from 'rmwc'
import TaxDialog from './TaxDialog'
const TaxTable=({plans})=>{
  const[open, setOpen] = React.useState(false)
  const[plan, setPlan] = React.useState(null)
  const[title, setTitle] = React.useState('')
  const[action, setAction] = React.useState(null)
  const [functionAction, setFunctionAction] = React.useState(null)


  const deletePlan=(plan)=>{
    setPlan(plan)
    setAction('delete')
    setTitle(`Você quer realmente excluir o plano ${plan.name}?`)
    setOpen(true)
  }

  const editPlan=(plan)=>{
    setPlan(plan)
    setAction('edit')
    setTitle(`Editar plano ${plan.name}`)
    setOpen(true)
  }

    return(
      <>
        
      
      <TaxDialog open={open} setOpen={setOpen} title={title} action={action} plan={plan}/>
 
        <DataTable className={"TabelaProdutos"}>
        <DataTableContent>
        <DataTableHead>
              <DataTableRow>
                <DataTableHeadCell>Código</DataTableHeadCell>
                <DataTableHeadCell>Nome</DataTableHeadCell>
                <DataTableHeadCell>Valor</DataTableHeadCell>
                <DataTableHeadCell>Tolerância</DataTableHeadCell>
                <DataTableHeadCell></DataTableHeadCell>
              </DataTableRow>
            </DataTableHead>
            <DataTableBody>
              {
                plans.map(plan=>{
                  return(
                    <DataTableRow key={plan.id}>
                      <DataTableCell>{plan.id}</DataTableCell>
                      <DataTableCell>{plan.name}</DataTableCell>
                      <DataTableCell>{plan.value}</DataTableCell>
                      <DataTableCell>{plan.tolerance}</DataTableCell>
                      <DataTableCell>
                      <Button label={"Editar"} className={"btnEdit"} onClick={()=>editPlan(plan)} icon="edit"/>  
                      </DataTableCell> 
                      <DataTableCell>
                        <Button label={"Excluir"} className={"btnDelete"} onClick={()=>deletePlan(plan)} icon="delete"/>  
                      </DataTableCell> 
                    </DataTableRow>
                  )
                })
              }
            </DataTableBody>
            </DataTableContent>
        </DataTable>
        </>
    )
}

export default TaxTable