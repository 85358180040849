import React from 'react'
import {
    Typography,
    Grid,
    GridRow,
    GridCell,
    Button,
    MenuItem,
    TextField,
    Snackbar, 
    SnackbarAction,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogButton,
    CircularProgress
} from 'rmwc'
import { PlanContext } from '../../PlanContext'
import { postPlan } from './TaxServices'
const TaxAdd =({setAdd})=>{
    const {addPlans} = React.useContext(PlanContext)
    const token = window.localStorage.getItem('token')
    const [message, setMessage] = React.useState('')   
    const [openSnack, setopenSnake] = React.useState(false) 

    const newTax = async event =>{
        event.preventDefault();
        const body ={
            name:event.target.name.value,
            value: event.target.value.value,
            max_card:event.target.max_card.value,
            tolerance: event.target.tolerance.value
        }
        if(await postPlan(token, body)){
            addPlans()
            setMessage(`Plano ${event.target.name.value} cadastrado com sucesso!`)
            setopenSnake(true)
            setTimeout(()=>{
                setAdd(false)
            },2000)
        }
      }
    

    return(
        <>
        <form onSubmit={newTax}>
                  <div className="formContainer">
              <Grid>    
                <GridRow>                 
                    <GridCell span={6}>
                    <TextField fullwidth placeholder="Nome" name="name" required/>
                    </GridCell>
                    <GridCell span={6}>
                    <TextField fullwidth type="text" placeholder="Valor" name="value" required/>
                    </GridCell>
                </GridRow>
                <GridRow>    
                    <GridCell span={6}>
                    <TextField fullwidth type="Number" placeholder="Numero de comandas" name="max_card" required/>
                    </GridCell>
                    <GridCell span={6}>
                    <TextField fullwidth type={"Number"} placeholder="Tolerância" name="tolerance" required/>
                    </GridCell>
                </GridRow>
                <GridRow>
                    <GridCell span={9}></GridCell>
                    <GridCell span={3}>
                    <Button label="Cadastrar" outlined icon="add" className={"BtnDefaultSearch"} type="submit"/> 
                    </GridCell>
                </GridRow>
                </Grid>
                </div>
                </form>
                <Snackbar
                open={openSnack}
                onClose={(evt) => setopenSnake(false)}
                message={message}
                dismissesOnAction
                action={
                  <SnackbarAction
                    label="OK"
                    onClick={() => console.log('OK')}
                  />
                }
                />
                </>
    )
}

export default TaxAdd