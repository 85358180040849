import { GET_COMPANIES } from "../../api"

export const getCompanies= async (token, paginate, search)=>{
    try {
        const {url, options} = GET_COMPANIES(token, paginate, search)
        const response = await fetch(url, options)
        if(!response.ok) throw new Error(`Error: ${response.statusText}`)
        const {company} = await response.json()
        return company.data
    } catch (error) {
        console.log(error)
        return null
    }
}