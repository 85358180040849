import React from 'react';
import { getBillings, sendBilling } from '../components/Charges/ChargeService'
export const ChargeContext = React.createContext();

export const ChargeStorage=({children})=>{
    const [data, setData] = React.useState([])
    const [loaded, setLoaded] = React.useState(true)
    const [paginate, setPaginate] = React.useState({total:0, perPage:10, page:1, lastpage:0})
    const token = window.localStorage.getItem('token')
    const getData = async (search=null)=>{
        try {
            const billings = await getBillings(token, paginate, search)
            setData(billings.data)
            setPaginate({total:billings.total, perPage:billings.perPage, page:billings.page, lastpage:billings.lastPage})
          } catch (error) {
          console.log(error)
        }finally{
          setLoaded(false)
        }
      }
      React.useEffect(()=>{
        getData()
      }, [])

      const paginateUpdate= async event =>{
        paginate.page=event.target.id
        setPaginate(paginate)
        getData()
      }
    return (
        <ChargeContext.Provider
          value={{ data, loaded, paginate, getData, paginateUpdate }}
        >
          {children}
        </ChargeContext.Provider>
      );
}