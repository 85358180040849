import React from 'react'
import { Typography, 
    TextField, Grid, 
    GridRow, 
    GridCell, 
    Button, 
    Switch,
    Select,
    DataTable,
    DataTableContent,
    DataTableRow,
    DataTableBody,
    DataTableCell
} from "rmwc";
import MainNav from '../../MainNav'
import { useNavigate } from 'react-router-dom';
import {CREATE_USER, GET_COMPANIES} from '../../api'
import useForm from '../../Hooks/useForm'
const AddUser =()=>{
    const [data, setData] = React.useState([])
    const [companyList, setCompanyList] = React.useState([])
    const [id, setId] = React.useState(0)
    const [value, setValue] = React.useState('')
    const [selected, setSelected] = React.useState(true)
    const [loading, setLoading] = React.useState(false)
    const navigate = useNavigate();
    const comp = useForm()
    const token = window.localStorage.getItem('token')
    const getData = async () =>{
        try {
            const paginate = {page:1, perPage:null}
            const {url, options} = GET_COMPANIES(token, paginate)
            const response = await fetch(url, options)
            if(!response.ok) throw new Error(`Error: ${response.statusText}`)
            const {company} = await response.json()
            setData(company.data)
        } catch (error) {
            console.log(error)
        }
    }


    
    const handleUser = async (event) =>{
        event.preventDefault()
        setLoading(true)
        try {
            const body = {
                name:event.target.name.value,
                email:event.target.email.value,
                password:event.target.password.value,
                cpf:event.target.cpf.value,
                phone:event.target.phone.value,
                role:event.target.role.value,
                company_id: event.target.company.value
            }
            const {url, options} = CREATE_USER(token, body)
            const response = await fetch(url, options)          
            if(!response.ok) throw new Error(response.statusText)
            navigate('/users')
        } catch (error) {
            console.log(error)
        }finally{
            setLoading(false)
        }
    }

    React.useEffect(()=>{
        getData()
    },[])

   // React.useEffect(()=>{
     //   updateList()
    //},[comp.value])

    return(
        <>
        <MainNav/>
        <div className={"PageContainer"}>
        <div className={"PageTitle"}>        
            <h1><Typography use="headline1">CADASTRO DE USUARIO</Typography></h1>             
        </div>
        <form  onSubmit={handleUser}>
            <div className="formContainer">
        <Grid>
            <GridRow>
                <GridCell span={12}>
                    <GridRow>
                        <GridCell span={6}><TextField fullwidth placeholder="Nome" name="name" required/></GridCell>
                        <GridCell span={6}> <TextField fullwidth placeholder="CPF" name="cpf" required/></GridCell>
                    </GridRow>
                    <GridRow>
                        <GridCell span={6}><TextField fullwidth placeholder="Email" name="email" required/></GridCell>
                        <GridCell span={6}> <TextField fullwidth placeholder="Telefone" name="phone" required/></GridCell>
                    </GridRow>
                    <GridRow>
                        <GridCell span={6}><TextField fullwidth placeholder="Senha" name="password" type="password" required/></GridCell>
                        <GridCell span={6}> <TextField fullwidth placeholder="Confirme a senha" name="password-confirm"  type="password" required/></GridCell>
                    </GridRow>
                    <GridRow>
                        <GridCell span={6}>
                        <Select
                            label="Tipo de Usuário"
                            name='role'
                            defaultValue="admin"
                            >
                                 <option value="admin">Admin</option>
                                 <option value="client">Cliente</option>
                                 <option value="manager">Gerente</option>
                            </Select>
                        </GridCell>
                        <GridCell span={6}>
                        <Select
                            label="Empresas"
                            name='company'
                            >
                                {
                                    data.map(compa=>{
                                        return(
                                            <option value={compa.id} key={compa.id}>{compa.name}</option>
                                        )
                                    })
                                }
                            </Select>
                        </GridCell>
                             
                    </GridRow>
                    
                    <br/>
                    <GridRow>
                        <GridCell span={6}>  
                        <Button className={"BtnDefaultTmenu"} label={loading?"Aguarde...":"Salvar"} icon="save" disabled={loading}/> 
                        <Button className={""} onClick={()=>{window.history.back()}} label="Voltar" />     
                        </GridCell>                        
                    </GridRow>    
                </GridCell>
            </GridRow>
        </Grid>
        </div>
        </form>
        </div>

        </>
    )
}

export default AddUser